<template>
    <v-container class="pa-0" fluid>
        <page-title
                :heading="$t('accounting.lang_accounting_displayDatev') "
                :icon="icon"
                :subheading="$t('accounting.lang_accounting_displayDatev')"
        ></page-title>
        <div class="app-main__inner">
            <datev-export-component/>
        </div>
    </v-container>
</template>

<script>
    //components
    import PageTitle from "../../../Layout/Components/PageTitle";
    import DatevExportComponent from "../../../components/accounting/datev/DatevExportComponent";


    export default {
        components: {
            DatevExportComponent,

            PageTitle,

        },
        data: () => ({
            icon: "pe-7s-next-2 icon-gradient bg-tempting-azure"
        })
    };
</script>

<style>
</style>